export enum GoogleDrivePermisisonType {
  user = "user",
  anyone = "anyone",
}

export const GoogleDrivePermisisonTypes = [
  {
    value: GoogleDrivePermisisonType.user,
    label: "Restricted",
    description: "Only you can open and view this file."
  },
  {
    value: GoogleDrivePermisisonType.anyone,
    label: "Anyone",
    description: "Anyone can open and view this file."
  },
];

export enum GoogleDriveMimeType {
  docs = "application/vnd.google-apps.document",
  sheets = "application/vnd.google-apps.spreadsheet",
  slides = "application/vnd.google-apps.presentation",
  drawings = "application/vnd.google-apps.drawing",
  pdfs = "application/pdf",
  images = "image/",
}

export const SupportedGoogleDriveAddFileTypes = [
  GoogleDriveMimeType.docs,
  GoogleDriveMimeType.sheets,
  GoogleDriveMimeType.slides,
  GoogleDriveMimeType.drawings,
];

export const GoogleDriveMimeTypes = [
  {
    label: "Document",
    icon: "iconoir:google-docs",
    color: "#3F80EA",
    value: GoogleDriveMimeType.docs,
  },
  {
    label: "Sheet",
    icon: "mdi:google-spreadsheet",
    color: "#0E9755",
    value: GoogleDriveMimeType.sheets,
  },
  {
    label: "Slide",
    color: "#EAAD00",
    icon: "ph:presentation",
    value: GoogleDriveMimeType.slides,
  },
  {
    label: "Drawing",
    color: "#D02E24",
    icon: "mdi:drawing-box",
    value: GoogleDriveMimeType.drawings,
  },
  {
    label: "PDF",
    color: "#FF0000",
    icon: "mdi:file-pdf",
    value: GoogleDriveMimeType.pdfs,
  },
  {
    label: "Image",
    color: "#FF0000",
    icon: "mdi:image",
    value: GoogleDriveMimeType.images,
  },
] as { label: string; icon: string; color: string; value: GoogleDriveMimeType }[];

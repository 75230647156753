export enum ResourceType {
  googleDrive = "google-drive",
  file = "file",
  link = "link",
}

export const ResourceTypes = [
  {
    value: ResourceType.file,
    label: "File",
  },
  {
    value: ResourceType.googleDrive,
    label: "Google Drive",
  },
  {
    value: ResourceType.link,
    label: "Link",
  },
];
